<template>
  <div>
    <CInput
      :placeholder="`${label.title} 주소 입력 후 [Enter]`"
      type="text"
      @change="search"
      v-model="base"
    >
      <template #label>
        <CRow class="mb-1">
          <CCol>{{label.title}}
            <CBadge :color="label.color">{{label.badge}}</CBadge>
            <CBadge color="dark"></CBadge>
          </CCol>
          <CCol>
            <BFormCheckbox v-model="isPlace" name="is-place-btn" switch>
              지명검색
            </BFormCheckbox>
          </CCol>
          <CCol class="text-right" v-show="labelType!=='user'">
            <CButton size="sm" shape="pill"
                     color="info" variant="outline"
                     @click="setUserAddr">
              예약자 주소
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CInput>
    <CInput
      :id="`addr_detail_${labelType}`"
      :placeholder="`${label.title} 상세 주소 입력 후 [Enter]`"
      type="text"
      @change="setAddr('detail', '')"
      @keyup.enter="setAddr('detail', '')"
      v-model="dtl"
    />
    <CRow>
      <CCol>
        <BTable v-show="showPlaceTable"
          small
          bordered
          responsive
          selectable
          select-mode="single"
          selected-variant="danger"
          sticky-header
          sticky-column
          ref="addrTable"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small text-center"
          :fields="placeFields"
          :items="places"
          @row-selected="placeSelected">
        </BTable>

        <BTable v-show="showAddrTable"
          small
          bordered
          responsive
          selectable
          select-mode="single"
          selected-variant="danger"
          sticky-header
          sticky-column
          ref="addrTable"
          thead-class="text-center"
          head-variant="light"
          class="text-nowrap small text-center"
          :fields="addrFields"
          :items="rows"
          @row-selected="addrSelected"
        >


<!--          <template #cell(selectBtn)="{item}">-->
<!--            <BButton class="small" size="sm" @click="setAddr('base', item)">선택</BButton>-->
<!--          </template>-->

        </BTable>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import '../../../common/HelperMixin'
import {apiCall, toastSync} from '../../../common/utils';



export default {
  name: 'AddrSearch',
  props: {
    labelType: {type: String, default: 'st'},
    addr: {type: String, default: ''},
    addrDtl: {type: String, default: ''},
    addrXy: {type: String, default: ''},
    addrSet: { type: Object, default: null}
  },

  data(){
    return {
      label: {
        title: '',
        badge: '',
        color: 'info'
      },
      isPlace: true,
      rows: [],
      places: [],
      base: '',
      dtl: '',
      poi: '',
      region: {
        rga: '',
        rg1: '',
        rg2: '',
        rg3: '',
        mNo: '',
        sNo: '',
        xy: ''
      },
      addrFields: [
        {key: 'address_name', label: '주소'},
        // {key: 'address.address_name', label: '주소..'},
        {key: 'address.address_name', label: '주소(구)' },
        // {key: 'selectBtn', label: '선택'}
      ],

      placeFields: [
        {key: 'place_name', label: '지명'},
        {key: 'road_address_name', label: '주소'},
        {key: 'address_name', label: '주소'},
      ],

      showAddrTable: false,
      showPlaceTable: false,
      detailDocId: ''
    }
  },
  computed: {

  },
  created(){
    switch(this.labelType){
      case 'st':
        this.label = { title : '출발지', badge: '보내는 분', color: 'primary' };
        break;
      case 'ed':
        this.label = { title : '도착지', badge: '받는 분', color: 'success' };
        break;
      case 'user':
        this.label = { title : '사용자', badge: '주소', color: 'info' };
        break;
      case 'rt1':
        this.label = { title : '경유지1', badge: '경유지1', color: 'warning' };
        break;
      case 'rt2':
        this.label = { title : '경유지2', badge: '경유지2', color: 'warning' };
        break;
      case 'rt3':
        this.label = { title : '경유지3', badge: '경유지3', color: 'warning' };
        break;
      case 'rt4':
        this.label = { title : '경유지4', badge: '경유지4', color: 'warning' };
        break;
      case 'rt5':
        this.label = { title : '경유지5', badge: '경유지5', color: 'warning' };
        break;
      default:
        break;
    }
    this.detailDocId = `addr_detail_${this.labelType}`
    this.base = this.addr;
    this.dtl = this.addrDtl;
  },
  mounted(){
  },
  methods: {
    resetForm(){
      this.rows = [];
      this.places = [];
      this.base = '';
      this.dtl = '';
      this.poi = '';
      this.region = {
        rga: '',
        rg1: '',
        rg2: '',
        rg3: '',
        mNo: '',
        sNo: '',
        xy: ''
      };
    },
    watchUpdate(e){
      console.log("watch--->",e)
    },

    setUserAddr(){
      if(this.addrSet!==null){
        this.base = this.addrSet.addr;
        this.dtl = this.addrSet.addrDtl;
        this.xy = this.addrSet.xy;
      }else{
        if( this.$store.state.user.addrXy ){
          this.base = this.$store.state.user.addr;
          this.dtl = this.$store.state.user.addrDtl;
          this.xy = this.$store.state.user.addrXy;
        }else{
          this.base = this.$store.state.user.center.addr;
          this.dtl = this.$store.state.user.center.addrDtl;
          this.xy = this.$store.state.user.center.addrXy;
          alert('사용자의 등록 주소가 없으므로 센터주소로 설정 합니다.');
        }

      }
      this.emitSelected();
    },
    search(){
      if(this.isPlace) this.searchPlace();
      else this.searchAddr();
    },
    async searchAddr(){
      console.log('searchAddr--->', this.base);
      try{
        this.showAddrTable = true;
        this.rows = [];
        const rs = await apiCall('get', '/api/geo/address/' + encodeURIComponent(this.base) );
        if(rs.code===200) {
          this.rows = rs.result;
          console.log( 'record count--->', this.rows );
          await toastSync(this.$bvToast, `검색 건수: ${this.rows.length}건 `, 'info', '주소 검색 결과');
        } else {
          await toastSync(this.$bvToast, rs.message)
        }
      }catch(err){
        console.error(err);
      }
    },

    async searchPlace(){
      console.log('searchAddr--->', this.base);
      try{

        this.places = [];
        const rs = await apiCall('get', '/api/geo/place/' + encodeURIComponent(this.base) );
        this.showPlaceTable = true;
        if(rs.code===200) {
          this.places = rs.result;
          await toastSync(this.$bvToast, `검색 건수: ${this.rows.length}건 `, 'info', '지명 검색 결과');
        } else {
          await toastSync(this.$bvToast, rs.message)
        }
      }catch(err){
        console.error(err);
      }
    },

    async addrSelected(items){
      if(!items.length) return;
      const item = items[0];
      console.log('addrSelected--->', item);
      await this.setAddr('base', item)
    },

    async placeSelected(items){
      if(!items.length) return;

      const item = items[0];
      this.poi = item.place_name;
      this.base = item.road_address_name;
      this.dtl = item.place_name;
      const {result} = await apiCall('get', '/api/geo/address/' + encodeURIComponent(item.road_address_name) );
      console.log('reault----->', result)
      await this.setAddr('place', result[0]);
    },

    async setAddr(cmd, item){
      console.log("setAddr cmd=", cmd)
      if(item==='') {
        if(cmd==='detail') this.emitSelected()
        return
      }
      console.log('setAddr--->', item);
      if(cmd==='base') {
        this.base = item.address_name;
        this.xy = item.x + ',' + item.y;
        this.region = {
          rga : item.address.address_name,
          rg1 : item.address.region_1depth_name,
          rg2 : item.address.region_2depth_name,
          rg3 : item.address.region_3depth_name,
          mNo : item.address.main_address_no,
          sNo : item.address.sub_address_no,
        }

        // document.getElementById(this.detailDocId).focus();
      }else if(cmd==='place'){
        this.xy = item.x + ',' + item.y;
        this.region = {
          rga : item.address.address_name,
          rg1 : item.address.region_1depth_name,
          rg2 : item.address.region_2depth_name,
          rg3 : item.address.region_3depth_name,
          mNo : item.address.main_address_no,
          sNo : item.address.sub_address_no,
        }
      }else{
        await toastSync(this.$bvToast, "상세주소가 입력 되었습니다", 'info', "주소입력")
      }

      this.showAddrTable = false;
      this.showPlaceTable = false;

      this.emitSelected();

    },
    emitSelected(){
      this.$emit('selected',
        {
          base: this.base,
          dtl: this.dtl,
          xy: this.xy,
          region: this.region
        });
      this.rows = [];
    }

  }
}
</script>
